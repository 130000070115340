/* eslint-disable */
import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import { playCircle } from "react-icons-kit/fa/playCircle";
import { openModal, closeModal } from "@redq/reuse-modal";
import Text from "common/components/Text";
import Image from "common/components/Image";
import Button from "common/components/Button";
import Heading from "common/components/Heading";
import Rating from "common/components/Rating";
import Container from "common/components/UI/Container";
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
  VideoGroup,
  VideoWrapper,
  CustomerWrapper,
  ImageWrapper,
  DownloadImageWrapper,
} from "./banner.style";
import banner from "common/assets/image/Banner.png";
import chrome from "common/assets/image/chrome.png";
import firefox from "common/assets/image/firefox.png";
import apple from "common/assets/image/apple.png";
import circleBorder from "common/assets/image/appModern/shape.svg";
// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/8ME-QAlW6Ww"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        client {
          id
          title
          image {
            publicURL
          }
        }
      }
    }
  `);
  const { client } = data.appModernJson;
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        default: {
          width: "auto",
          height: "auto",
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          {/* <Fade up>
            <RatingInfo>
              <Rating rating={4} />
              4.9 of 5 By <img src={microsoft} alt="Microsoft" />
            </RatingInfo>
          </Fade> */}
          <Fade up delay={100}>
            <Heading as="h1" content="Ponder" />
          </Fade>
          <Fade up delay={200}>
            <Text content="Daily journal from your new tab." />
          </Fade>
          {/* <Fade up delay={300}>
            <ButtonGroup>
              <Button className="primary" title="Start Free trail" />
              <Button
                className="text"
                variant="textButton"
                icon={<Icon icon={playCircle} />}
                iconPosition="left"
                title="Watch Video"
              />
            </ButtonGroup>
          </Fade> */}
          <DownloadImageWrapper>
            <a href="https://chrome.google.com/webstore/detail/ponder/iahlambfobindandliebffikfgfmppom?hl=en">
              <img src={chrome} />
            </a>
          </DownloadImageWrapper>
          <DownloadImageWrapper className="center">
            <a href="https://apps.apple.com/app/id1557785005/">
              <img src={apple} />
            </a>
          </DownloadImageWrapper>
          <DownloadImageWrapper className="right">
            <a href="https://addons.mozilla.org/en-US/firefox/addon/ponderapp/">
              <img src={firefox} />
            </a>
          </DownloadImageWrapper>
          <br />
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            <Image src={banner} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
